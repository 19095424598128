<template>
    <div class="section maverick-cta-section-02 section-padding-test noselect nodrag" style="padding-bottom: 10%;">
        <div class="container">
            <div class="cta-wrap test6">
                <div class="row align-items-center">
                    <div class="col-xl-9 col-lg-8">
                        <div class="cta-content">
                            <div class="cta-icon">
                                <img src="../../assets/images/cta-icon2.png" alt="">
                            </div>
                            <p>Wir bieten das beste Kundenerlebnis</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4">
                        <div class="cta-btn">
                            <a class="btn btn-white" href="tel:+4940696355630">+49 40 696355630</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AboutCta',
}
</script>
<style></style>