<template>
  <!-- <div id="preloader">
      <div class="preloader">
          <span></span>
          <span></span>
      </div>
    </div> -->
  <MainLayout>
    <SliderView />
    <ServisSectionView />
    <CtaView />
    <AboutSectionView />
    <CtoSectionView />
    <CounterSectionView />
    <OurProjectSectionView />
    <TestimonialSectionView />
    <BrandsSectionView />
  </MainLayout>
  
</template>

<script>

import MainLayout from '../components/MainLayout.vue'

import SliderView from '../components/Home/SliderView.vue'
import ServisSectionView from '../components/Home/ServisSectionView.vue'
import CtaView from '../components/Home/CtaView.vue'
import AboutSectionView from '../components/Home/AboutSectionView.vue'
import CtoSectionView from '../components/Home/CtoSectionView.vue'
import CounterSectionView from '../components/Home/CounterSectionView.vue'
import OurProjectSectionView from '../components/Home/OurProjectSectionView.vue'
import TestimonialSectionView from '../components/Home/TestimonialSectionView.vue'

import BrandsSectionView from '../components/General/BrandsSectionView.vue'
export default {
  name: 'HomeView',
  components: {
    MainLayout,SliderView,ServisSectionView,
    TestimonialSectionView,CtaView,AboutSectionView,CtoSectionView,
    CounterSectionView,OurProjectSectionView,BrandsSectionView
  }
}
</script>

