<template>
    <div class="section maverick-choose-us-section section-padding test4 noselect nodrag">
        <div class="container">
            <div class="choose-us-wrap">
                <div class="section-title text-center">
                    <h3 class="sub-title">GRÜNDE, UNS ZU WÄHLEN</h3>
                    <h2 class="title">Wir bieten wirklich herausragende IT-Lösungen</h2>
                </div>
                <div class="choose-us-content-wrap">
                    <div class="row">
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/ser-icon9.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a><router-link to="/service">Infrastruktur<br> technologie</router-link></a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technikteams. Wir finden für Sie
                                        ein komplettes Team.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/counter-3.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a><router-link to="/service">Sicherheits<br>management</router-link></a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technikteams. Wir finden für Sie
                                        ein komplettes Team.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/ser-icon10.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a><router-link to="/service">Hochprofessionelle <br>Teammitglieder</router-link></a>
                                    </h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technikteams. Wir finden für Sie
                                        ein komplettes Team.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/ser-icon12.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a><router-link to="/service">Daten sichern & <br> Management</router-link></a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technikteams. Wir finden für Sie
                                        ein komplettes Team.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseUsWrap'
}
</script>

<style></style>