<template>
  <div class="section maverick-case-study-section section-padding-02 noselect nodrag">
    <div class="container">
      <div class="case-study-wrap">
        <div class="section-title text-center">
          <h3 class="sub-title">Studienfälle</h3>
          <h2 class="title">Unsere Projekte machen uns stolz</h2>
        </div>
        <div class="case-study-content-wrap">
          <swiper :slides-per-view="3" :space-between="30" class="case-study-active">
            <swiper-slide>
              <div class="single-case-study">
                <div class="case-study-img">
                  <a href="#"><img :src="require('@/assets/images/case-11.jpg')" alt=""></a>
                  <div class="top-meta">
                    <img :src="require('@/assets/images/case-meta1.png')" alt="">
                  </div>
                </div>
                <div class="case-study-content">
                  <h3 class="title"><a href="#">Blockchain-Technologie</a></h3>
                  <p>Discover Inc</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="single-case-study">
                <div class="case-study-img">
                  <a href="#"><img :src="require('@/assets/images/case-22.jpg')" alt=""></a>
                  <div class="top-meta">
                    <img :src="require('@/assets/images/case-meta2.png')" alt="">
                  </div>
                </div>
                <div class="case-study-content">
                  <h3 class="title"><a href="#">Blockchain-Technologie</a></h3>
                  <p>Discover Inc</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="single-case-study">
                <div class="case-study-img">
                  <a href="#"><img :src="require('@/assets/images/case-33.jpg')" alt=""></a>
                  <div class="top-meta">
                    <img :src="require('@/assets/images/case-meta3.png')" alt="">
                  </div>
                </div>
                <div class="case-study-content">
                  <h3 class="title"><a href="#">Blockchain-Technologie</a></h3>
                  <p>Discover Inc</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="single-case-study">
                <div class="case-study-img">
                  <a href="#"><img :src="require('@/assets/images/case-33.jpg')" alt=""></a>
                  <div class="top-meta">
                    <img :src="require('@/assets/images/case-meta3.png')" alt="">
                  </div>
                </div>
                <div class="case-study-content">
                  <h3 class="title"><a href="#">Blockchain-Technologie</a></h3>
                  <p>Discover Inc</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper,SwiperSlide } from 'swiper/vue';
import 'swiper/css';

export default {
  name: 'OurProjectSectionView',
  components: {
    Swiper,
    SwiperSlide
  }
}
</script>
<style lang="">
    
</style>