<template>
    <MainLayout>
        <BannerView title="UnserTeam" breadcrumb="Home" breadcrumb-item="UnserTeam"/> 
        <div class="section maverick-team-section maverick-team-section-03 section-padding">
        <div class="container">
            <div class="team-wrap">
                <div class="row">
                    <div class="col-lg-3 col-sm-6" v-for="member in teamMembers" :key="member.id">
                        <div class="single-team">
                            <div class="team-img">
                                <a><img :src="member.image" :alt="member.name"></a>
                            </div>
                            <div class="team-content">
                                <h3 class="name"><a>{{ member.name }}</a></h3>
                                <span class="designation">{{ member.position }}</span>
                                <div class="team-social">
                                    <ul class="social">
                                        <li v-for="social in member.socialMedia" :key="social.name">
                                            <a :href="social.link"><i :class="social.icon"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </MainLayout>
</template>

<script>

import MainLayout from '../components/MainLayout.vue'

import BannerView from '../components/General/BannerView.vue'
export default {
    name: 'TeamView',
    components: {
        MainLayout, BannerView
    },
    data() {
    return {
        teamMembers: [
            {
                id: 1,
                name: 'Stefan Muhlack',
                position: 'CEO',
                image: require('../assets/images/team/team.jpg'),
                socialMedia: [
                    { name: 'facebook', icon: 'fab fa-facebook-f', link: '#' },
                    { name: 'twitter', icon: 'fab fa-twitter', link: '#' },
                    { name: 'dribbble', icon: 'fab fa-dribbble', link: '#' },
                    { name: 'behance', icon: 'fab fa-behance', link: '#' }
                ]
            },
            {
                id: 2,
                name: 'Jennifer Spehr',
                position: 'Persönlicher Assistent des CEO',
                image: require('../assets/images/team/jennifers.png'),

                socialMedia: [
                    { name: 'facebook', icon: 'fab fa-facebook-f', link: '#' },
                    { name: 'twitter', icon: 'fab fa-twitter', link: '#' },
                    { name: 'dribbble', icon: 'fab fa-dribbble', link: '#' },
                    { name: 'behance', icon: 'fab fa-behance', link: '#' }
                ]
            },
            {
                id: 3,
                name: 'Dirk Möbius',
                position: 'Backend-Entwickler',
                image: require('../assets/images/team/team-1.jpg'),

                socialMedia: [
                    { name: 'facebook', icon: 'fab fa-facebook-f', link: '#' },
                    { name: 'twitter', icon: 'fab fa-twitter', link: '#' },
                    { name: 'dribbble', icon: 'fab fa-dribbble', link: '#' },
                    { name: 'behance', icon: 'fab fa-behance', link: '#' }
                ]
            },
            {
                id: 4,
                name: 'Florian Wennerström',
                position: 'Frontend-Entwickler',
                image: require('../assets/images/team/team-2.jpg'),

                socialMedia: [
                    { name: 'facebook', icon: 'fab fa-facebook-f', link: '#' },
                    { name: 'twitter', icon: 'fab fa-twitter', link: '#' },
                    { name: 'dribbble', icon: 'fab fa-dribbble', link: '#' },
                    { name: 'behance', icon: 'fab fa-behance', link: '#' }
                ]
            },
            {
                id: 5,
                name: 'Philipp Glaw',
                position: 'DevOps-Ingenieur',
                image: require('../assets/images/team/team-3.jpg'),

                socialMedia: [
                    { name: 'facebook', icon: 'fab fa-facebook-f', link: '#' },
                    { name: 'twitter', icon: 'fab fa-twitter', link: '#' },
                    { name: 'dribbble', icon: 'fab fa-dribbble', link: '#' },
                    { name: 'behance', icon: 'fab fa-behance', link: '#' }
                ]
            },
            {
                id: 6,
                name: 'Amin Arzhang',
                position: 'Frontend-Entwickler',
                image: require('../assets/images/team/teamss.jpg'),

                socialMedia: [
                    { name: 'facebook', icon: 'fab fa-facebook-f', link: '#' },
                    { name: 'twitter', icon: 'fab fa-twitter', link: '#' },
                    { name: 'dribbble', icon: 'fab fa-dribbble', link: '#' },
                    { name: 'behance', icon: 'fab fa-behance', link: '#' }
                ]
            },
            {
                id: 7,
                name: 'Neda Rafeeinia',
                position: 'Backend-Entwickler',
                image: require('../assets/images/team/neda1.png'),

                socialMedia: [
                    { name: 'facebook', icon: 'fab fa-facebook-f', link: '#' },
                    { name: 'twitter', icon: 'fab fa-twitter', link: '#' },
                    { name: 'dribbble', icon: 'fab fa-dribbble', link: '#' },
                    { name: 'behance', icon: 'fab fa-behance', link: '#' }
                ]
            },
            {
                id: 8,
                name: 'Tahar',
                position: 'Backend-Entwickler',
                image: require('../assets/images/team/team-4.jpg'),

                socialMedia: [
                    { name: 'facebook', icon: 'fab fa-facebook-f', link: '#' },
                    { name: 'twitter', icon: 'fab fa-twitter', link: '#' },
                    { name: 'dribbble', icon: 'fab fa-dribbble', link: '#' },
                    { name: 'behance', icon: 'fab fa-behance', link: '#' }
                ]
            },
        ]
    }
}
,
    mounted() {
        this.fetchTeamMembers();
    },
    methods: {
        fetchTeamMembers() {
            // Fetch data from an API or other sources
            // For example: axios.get('/api/team').then(response => this.teamMembers = response.data)
        }
    }
}
</script>

<style>
.banner-image {
    background-image: url(../assets/images/bg/page-banner.jpg);
}
</style>