<template>
  <div class="test2 section maverick-about-section section-padding noselect nodrag">
    <div class="shape-1"></div>
    <div class="container">
      <div class="about-wrap">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-img-wrap">
              <div class="about-img about-img-big">
                <img :src="require('@/assets/images/about-11.jpg')" alt="">
              </div>
              <div class="about-img about-img-sm">
                <img :src="require('@/assets/images/about-22.jpg')" alt="">
                <div class="shape-01"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content-wrap">
              <div class="section-title">
                <h3 class="sub-title">WER WIR SIND</h3>
                <h2 class="title">Hoch individualisiertes IT-Design, Management- und Support-Services.</h2>
              </div>
              <p>Beschleunigen Sie Innovationen mit erstklassigen Technikteams. Wir werden Ihnen ein komplettes
                Remote-Team unglaublich talentierter Freelancer für alle Ihre Softwareentwicklungsbedürfnisse zuordnen.
              </p>
              <div class="play-btn">
                <a class="popup-video" href="#"><i
                    class="fas fa-play"></i> <span>Wie wir arbeiten</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutSectionView'
}
</script>
<style scoped>
.test2 {
  background-image: url(../../assets/images/bg/about-bg.png);
}
</style>