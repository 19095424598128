<template>
    <MainLayout>
        <BannerView title="Impressum" breadcrumb="Home" breadcrumbItem="Impressum" />
        <div class="section blog-details-section section-padding-02">
            <div class="container">
                <div class="blog-details-wrap">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <div class="blog-details-post">
                                <div class="single-blog-post single-blog">
                                    <div class="blog-content blog-padding">
                                        <h3 class="title">
                                            Impressum - Maverick Media Counsulting GmbH
                                        </h3>
                                        <p class="text">ABC-Str. 21</p>
                                        <p class="text">20354 Hamburg</p>
                                        <p class="text"><b>Tel: +49 40 696355630</b></p>
                                        <p class="text"><b>info@maverick-media.de</b></p>
                                        <p class="text">Sitz der Gesellschaft: <b>Hamburg</b></p>
                                        <p class="text">Amtsgericht Hamburg,<b> HRB 123416</b></p>
                                        <p class="text">Geschäftsführung:<b> Stefan Muhlack</b></p>
                                        <p class="text">Die Umsatzsteuer-ID:<b>DE285310431</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import BannerView from '@/components/General/BannerView.vue';

import MainLayout from '../components/MainLayout.vue'
export default {
    name: 'ImpressumView',
    components: {
        MainLayout,BannerView
    }
}
</script>
<style scoped>
.section-padding-02 {
    padding-top: 75px;
}
</style>