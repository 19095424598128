<template>
  <div id="header" class="section header-section sticky noselect nodrag">
    <div class="container">
      <div class="header-wrap">
        <div class="header-logo">
          <a><router-link to="/"><img :src="require('@/assets/images/logos.svg')" alt=""></router-link></a>
        </div>
        <div class="header-menu d-none d-lg-block">
          <ul class="main-menu">
            <li class="active-menu">
              <a><router-link to="/">Home</router-link></a>
            </li>
            <li>
              <a><router-link to="/about">Über Uns</router-link></a>
            </li>
            <li>
              <a><router-link to="/team">Unser Team</router-link></a>
            </li>
            <li>
              <a><router-link to="/service">Service</router-link></a>
            </li>
            <li>
              <a><router-link to="/why-us">Warum uns wählen</router-link></a>
            </li>
            <li>
              <a><router-link to="/contact">Kontakt</router-link></a>
            </li>
          </ul>
        </div>
        <div class="header-meta">
          <div class="header-btn d-none d-xl-block">
            <a class="btn"><router-link to="./contact">Loslegen</router-link></a>
          </div>
        </div>
        <div class="burger-menu-icon d-lg-none" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="mobile-menu" v-bind:class="{ 'show': isMenuOpen }">
          <div class="menu-content">
            <div class="mobile-menu-header">
              <div class="mobile-menu-logo">
                <img :src="require('@/assets/images/logo-white.png')" alt="Logo">
              </div>
              <div class="mobile-menu-close" @click="toggleMenu">
                <i class="flaticon-close-1"></i>
              </div>
            </div>
            <ul style="margin-top: 60px;">
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/about">Über Uns</router-link></li>
              <li><router-link to="/team">Unser Team</router-link></li>
              <li><router-link to="/service">Service</router-link></li>
              <li><router-link to="/why-us">Warum uns wählen</router-link></li>
              <li><router-link to="/contact">Kontakt</router-link></li>
            </ul>
          </div>
          <div class="menu-overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'HeaderSectionView',
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
}
</script>


<style scoped>
.burger-menu-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
}

.burger-menu-icon span {
  display: block;
  height: 3px;
  background-color: #086ad8;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: transparent;
  z-index: 100;
  transition: left 0.3s;
  display: flex;
}

.mobile-menu.show {
  left: 0;
}
.menu-content {
  width: 60%;
  background: #000c29;
  padding: 35px 30px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  color: #ffffff;
}

.menu-overlay {
  width: 40%;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
}
.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
}

.mobile-menu-logo img {
  width: 150px;
}

.mobile-menu-close {
  cursor: pointer;
  font-size: 14px;
  color: #fff;
}

@media (min-width: 992px){

  .burger-menu-icon,
  .mobile-menu {
    display: none !important;
  }
}

</style>