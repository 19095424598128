import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutUsView from '../views/AboutUsView.vue'
import TeamView from '../views/TeamView.vue'
import ServiceView from '../views/ServiceView.vue'
import WhyUsView from '../views/WhyUsView.vue'
import ContactUsView from '../views/ContactUsView.vue'
import DatenschutzView from '../views/DatenschutzView.vue'
import ImpressumView from '../views/ImpressumView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'AboutUsView',
    component: AboutUsView,
  },
  {
    path: '/team',
    name: 'TeamView',
    component: TeamView,
  },
  {
    path: '/service',
    name: 'ServiceView',
    component: ServiceView,
  },
  {
    path: '/why-us',
    name: 'WhyUsView',
    component: WhyUsView,
  },
  {
    path: '/contact',
    name: 'ContactUsView',
    component: ContactUsView,
  },
  {
    path: '/datenschutz',
    name: 'DatenschutzView',
    component: DatenschutzView,
  },
  {
    path: '/impressum',
    name: 'ImpressumView',
    component: ImpressumView,
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
