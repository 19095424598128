<template>
    <div class="section maverick-team-section maverick-team-section-02 section-padding-mav test7">
        <div class="container">
            <div class="team-wrap">
                <div class="section-title text-center">
                    <h1 class="sub-title title-33 noselect nodrag">{{ title }}</h1>
                    <h2 v-if="contentH2" class="title white">{{ contentH2 }}</h2>
                    <h4 v-if="contentH4" class="title white">{{ contentH4 }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MaverickSectionView',
    props: {
        title: {
            type: String
        },
        contentH2: {
            type: String
        },
        contentH4: {
            type: String
        }
    }
}
</script>

<style>
    .test7 {
        background-image: url(../../assets/images/bg/hero-bg5.jpg) !important;
    }
</style>