<template>
  <div class="section maverick-counter-section noselect nodrag">
    <div class="container">
      <div class="counter-wrap">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="single-counter">
              <div class="counter-img">
                <img :src="require('@/assets/images/counter-1.png')" alt="">
              </div>
              <div class="counter-content">
                <span class="counter">1790</span>
                <p>Zufriedene Kunden</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="single-counter">
              <div class="counter-img">
                <img :src="require('@/assets/images/counter-2.png')" alt="">
              </div>
              <div class="counter-content">
                <span class="counter">491</span>
                <p>Abgeschlossene Projekte</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="single-counter">
              <div class="counter-img">
                <img :src="require('@/assets/images/counter-3.png')" alt="">
              </div>
              <div class="counter-content">
                <span class="counter">245</span>
                <p>Qualifizierte Experten</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="single-counter">
              <div class="counter-img">
                <img :src="require('@/assets/images/counter-1.png')" alt="">
              </div>
              <div class="counter-content">
                <span class="counter">1090</span>
                <p>Medienbeiträge</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
}
<script>
export default {
  name: 'CounterSectionView'
}
</script>

<style></style>