<template>
  <div class="section maverick-service-section section-padding-02 noselect nodrag">
    <div class="container">
      <div class="service-wrap">
        <div class="section-title text-center">
          <h3 class="sub-title noselect nodrag">Was wir anbieten</h3>
          <h2 class="title noselect nodrag">Wir bieten wirklich herausragende digitale Lösungen.</h2>
        </div>
        <div class="service-content-wrap noselect nodrag">
          <div class="row">
            <div class="col-xl-3 col-sm-6">
              <div class="service-item service-01">
                <div class="service-img">
                  <img :src="require('@/assets/images/ser-icon1.png')" alt="">

                </div>
                <div class="service-content">
                  <h3 class="title"><a><router-link to="/service">Maßgeschneiderte Softwarelösung</router-link></a></h3>
                  <p>Beschleunigen Sie Innovationen mit erstklassigen Technikteams. Wir werden Ihnen ein komplettes
                    Remote-Team zuordnen.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6">
              <div class="service-item">
                <div class="service-img">
                  <img :src="require('@/assets/images/ser-icon2.png')" alt="">
                </div>
                <div class="service-content">
                  <h3 class="title"><a><router-link to="/service">Geschäftstechnologie Lösung</router-link></a></h3>
                  <p>Beschleunigen Sie Innovationen mit erstklassigen Technikteams. Wir werden Ihnen ein komplettes
                    Remote-Team zuordnen.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6">
              <div class="service-item service-03">
                <div class="service-img">
                  <img :src="require('@/assets/images/ser-icon3.png')" alt="">
                </div>
                <div class="service-content">
                  <h3 class="title"><a><router-link to="/service">Wiederherstellung und IT-Sicherheit</router-link></a></h3>
                  <p>Beschleunigen Sie Innovationen mit erstklassigen Technikteams. Wir werden Ihnen ein komplettes
                    Remote-Team zuordnen.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6">
              <div class="service-item">
                <div class="service-img">
                  <img :src="require('@/assets/images/ser-icon4.png')" alt="">
                </div>
                <div class="service-content">
                  <h3 class="title"><a><router-link to="/service">Ideen generieren und Lösungen finden</router-link></a></h3>
                  <p>Beschleunigen Sie Innovationen mit erstklassigen Technikteams. Wir werden Ihnen ein komplettes
                    Remote-Team zuordnen.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServisSectionView'
}
</script>
<style></style>