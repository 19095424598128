<template>
    <div class="section maverick-testimonial-section-02 section-padding test11 noselect nodrag">
        <div class="container">
            <div class="testimonial-wrap-02">
                <div class="swiper-container testimonial-02-active">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="single-testimonial-02">

                                <div class="testimonial-content">
                                    <img src="../../assets/images/testi-icon2.png" alt="">
                                    <p>Unsere Projekte sind aus verschiedenen Anwendungsgebieten. Wir erstellen und begleiten E-Commerce Projekte, Finanzprodukte und Projekte aber auch Digitalisierungsprozesse. 

Dabei begleiten wir in agilen oder auch klassischen Projektformen unsere Kunden bereits bei der Projektanbahnung. Gern versuchen wir bereits hier gemeinsam mit dem Kunden Optimierungspotentiale auszuloten und in die Lösungsansätze einfließen zu lassen. 

Einen hohen Grad an Transparenz erzielen wir mit regelmäßigen und zusätzlichen außerplanmäßigen Abstimmungen und Schulterblicken. So können wir gewährleisten, dass jederzeit eine hohe Übereinstimmung mit den formulierten Zielen des Projektes stattfindet.

Aber auch die Begleitung der Installations- und Einführungsprozesse wird von uns vorgenommen. So erreichen wir einen durchgehenden zieloptimierten Prozess bis hin zum Anwender der neuen Softwarelösung.

Darüber hinaus bieten wir nach der Einführung der Softwarelösung selbstverständlich Service, Support und Betrieb in verschiedenen Servicestufen an. </p>
                                    <span class="name">Stefan Muhlack </span>
                                    <span class="designation"> / CEO, Maverick</span>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'KundenBewertungView',

}
</script>

<style>
.test11 {
    background: url(../../assets/images/bg/testi-bg2.jpg) !important
}
</style>