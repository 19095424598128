<template>
    <div class="section maverick-about-section-07 section-padding noselect nodrag">
        <div class="shape-1"></div>
        <div class="container">
            <div class="about-wrap">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-img-wrap">
                            <img class="shape-1" src="../../assets/images/shape/about-shape2.png" alt="">
                            <div class="about-img">
                                <img src="../../assets/images/about-3.jpg" alt="">
                            </div>
                            <div class="about-img about-img-2">
                                <img src="../../assets/images/about-4.jpg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-content-wrap">
                            <div class="section-title">
                                <h3 class="sub-title">WER WIR SIND</h3>
                                <h2 class="title">Lösungen sind unsere Leidenschaft</h2>
                            </div>
                            <p class="text">Wir sind ein schlagkräftiges Team ausgesuchter Menschen. Mit Kompetenz und
                                Leidenschaft gehen wir an unsere Aufgaben und Herausforderungen. Es macht uns stark und
                                stolz, unseren Kunden für die verschiedensten Probleme und Problemstellungen häufig
                                einfache und effiziente Lösungen anbieten zu können.
                                Wobei natürlich "einfach" immer im Auge des Betrachters steht, denn technologisch kommen
                                schnell durchaus komplexe Systeme zustande. Beim Benutzer bleibt es bei uns aber
                                konsequent einfach!</p>
                            <div class="about-list-03">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="about-list-item-03">
                                            <h3 class="title">Unsere Mission</h3>
                                            <p>Seit nunmehr 70 Jahren forscht die Menschheit in den Bereichen der
                                                künstlichen Intelligenz und dem maschinellen Lernen. Mit den Computern
                                                der heutigen Generationen sind die Berechnungen und die Algorithmen
                                                wesentlich schneller und effizienter geworden.</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="about-list-item-03">
                                            <h3 class="title">Individueller Code</h3>
                                            <p>Diese Leistungssteigerung führte dazu, dass in den letzten Jahren große
                                                Fortschritte gemacht und die technischen Möglichkeiten vielfältig
                                                wurden. Geht man weiter davon aus, dass sich die technischen
                                                Geschwindigkeiten alle 3 Jahre fast verzehnfachen kann man ahnen, dass
                                                wir in einem elementaren Trendwechsel stecken.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'AboutContentWrap',

}
</script>

<style></style>