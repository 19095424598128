<template>
  <div class="test1 section maverick-hero-section noselect nodrag">
    <div class="shape-2"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="hero-content">
            <h3 class="sub-title"><span>HOT !</span> Wir können alle digitalen Dienste verwalten.</h3>
            <h2 class="title">Ihr Partner für digitale Lösungen</h2>
            <p>Wir bieten das reaktionsfähigste und funktionalste IT-Design für Unternehmen und Geschäfte weltweit.</p>
            <div class="hero-btn" data-aos="fade-up" data-aos-delay="1000">
              <a class="btn"><RouterLink to="/about">Read More</RouterLink></a>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="hero-images">
            <div class="images">
              <img :src="require('@/assets/images/hero-img111a.png')" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderView'
}
</script>
<style scoped>
.test1 {
  background-image: url(../../assets/images/bg/hero-bg1.png);
}
</style>