<template>
    <div class="section contact-map-section noselect nodrag fixed-map">
        <div class="contact-map-wrap fixed-map">
            <iframe scrolling="no" marginheight="0" marginwidth="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.226937187411!2d9.982740777007152!3d53.55371625918688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18b392635946d%3A0x33516c8c10f28a6f!2sMaverick%20Media%20Consulting%20GmbH!5e0!3m2!1sen!2sde!4v1702283945199!5m2!1sen!2sde"
                title="New York University" aria-label="New York University" frameborder="0"></iframe>
        </div>
    </div>
</template>
<script>
export default {
    name: 'mapSection'
}
</script>
<style>
    fixed-map {
        position: fixed;
    }
</style>