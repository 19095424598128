<template>
    <div class="section contact-info-section section-padding-02 noselect nodrag">
        <div class="container">
            <div class="contact-info-wrap">
                <div class="row">
                    <div class="col-lg-4 col-sm-12">
                        <div class="single-contact-info text-center">
                            <div class="info-icon">
                                <img src="../../assets/images/info-1.png" alt="">
                            </div>
                            <div class="info-content">
                                <h5 class="title">Rufen Sie uns an</h5>
                                <p>+49 (0)40 69 63 55 63 0</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <div class="single-contact-info text-center">
                            <div class="info-icon">
                                <img src="../../assets/images/info-2.png" alt="">
                            </div>
                            <div class="info-content">
                                <h5 class="title">Schreiben Sie uns eine Zeile</h5>
                                <p>info@maverick-media.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <div class="single-contact-info text-center">
                            <div class="info-icon">
                                <img src="../../assets/images/info-3.png" alt="">
                            </div>
                            <div class="info-content">
                                <h5 class="title">Besuchen Sie unser Büro</h5>
                                <p>ABC Str. 21, 20354 Hamburg</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ContactInfo'
}
</script>

<style>
    
</style>