<template>
  <div class="section maverick-cta-section section-padding noselect nodrag">
    <div class="container">
      <div class="cta-wrap">
        <div class="cta-icon">
          <img :src="require('@/assets/images/cta-icon1.png')" alt="">

        </div>
        <div class="cta-content text-center">
          <p>Hören Sie auf, Zeit und Geld für digitale Lösungen zu verschwenden. 
            <a><router-link to="/contact">Sprechen Sie mit uns</router-link></a>
          </p> 
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CtaView',
}
</script>
<style></style>