<template>
  <div class="section techwix-testimonial-section section-padding-02 noselect nodrag">
    <div class="container">
      <div class="testimonial-wrap">
        <div class="row">
          <div class="col-lg-6">
            <div class="testimonial-img">
              <div class="shape-1">
                <img :src="require('@/assets/images/shape/testi-shape1.png')" alt="">
              </div>
              <img :src="require('@/assets/images/testi-img.png')" alt="">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="testimonial-content-wrap">
              <div class="swiper-container testimonial-active">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="single-testimonial">
                      <img :src="require('@/assets/images/testi-icon.png')" alt="">
                      <p>Beschleunige Innovationen mit erstklassigen Technikteams - jenseits von mehr stoischen Dingen,
                        dies entlang, oh, dies, das ist toll, Seekuh </p>
                      <span class="name">Stefan Muhlack</span>
                      <span class="designation"> / Geschäftsführer, Maverick</span>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="single-testimonial">
                      <img :src="require('@/assets/images/testi-icon.png')" alt="">
                      <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey
                        this this wow manatee </p>
                      <span class="name">Mike Holder </span>
                      <span class="designation"> / CEO, Harlond inc</span>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="single-testimonial">
                      <img :src="require('@/assets/images/testi-icon.png')" alt="">
                      <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey
                        this this wow manatee </p>
                      <span class="name">Mike Holder </span>
                      <span class="designation"> / CEO, Harlond inc</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TestimonialSectionView'
}
</script>
<style></style>