<template>
    <MainLayout>
        <div class="section page-banner-section banner-image noselect nodrag">
            <div class="shape-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="944px" height="894px">
                <defs>
                    <linearGradient id="PSgrad_0" x1="88.295%" x2="0%" y1="0%" y2="46.947%">
                    <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
                    <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
                    </linearGradient>
                </defs>
                <path
                    fill-rule="evenodd"
                    fill="rgb(43, 142, 254)"
                    d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z"
                />
                <path
                    fill="url(#PSgrad_0)"
                    d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z"
                />
                </svg>
            </div>
            <div class="shape-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                <path
                    fill-rule="evenodd"
                    stroke="rgb(255, 255, 255)"
                    stroke-width="1px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    opacity="0.302"
                    fill="none"
                    d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z"
                />
                </svg>
            </div>
            <div class="shape-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="515px" height="515px">
                <defs>
                    <linearGradient id="PSgrad_0" x1="0%" x2="89.879%" y1="0%" y2="43.837%">
                    <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
                    <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
                    </linearGradient>
                </defs>
                <path
                    fill-rule="evenodd"
                    fill="rgb(43, 142, 254)"
                    d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z"
                />
                <path
                    fill="url(#PSgrad_0)"
                    d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z"
                />
                </svg>
            </div>
            <div class="shape-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                <path
                    fill-rule="evenodd"
                    stroke="rgb(255, 255, 255)"
                    stroke-width="1px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="none"
                    d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z"
                />
                </svg>
            </div>
            <BannerView title="Datenschutz" breadcrumb="Home" breadcrumbItem="Datenschutz"/>
            </div>
            <div class="section blog-details-section section-padding-02">
            <div class="container">
                <div class="blog-details-wrap">
                <div class="row">
                    <div class="col-xl-12 col-lg-12">
                    <div class="blog-details-post">
                        <div class="single-blog-post single-blog">
                        <div class="blog-image">
                            <a href="#"
                            ><img src="../assets/images/blog/blog-s-33.jpg" alt=""
                            /></a>
                            <div class="top-meta">
                            <span class="date"><span>25</span>Mai</span>
                            </div>
                        </div>
                        <div class="blog-content blog-padding">
                            <h3 class="title">
                            Datenschutz - Maverick Media Counsulting GmbH
                            </h3>
                            <p class="text">
                            Die unter dieser Adresse erreichbare Website ist ein Angebot
                            der FirmaMAVERICK MEDIA CONSULTING GMBH. Die MAVERICK MEDIA
                            CONSULTING GMBH nimmt den Datenschutz ernst. Die Erhebung
                            Ihrer Daten erfolgt im Rahmen der gesetzlichen Vorschriften.
                            Über diese können Sie sich unter www.datenschutz.de oder
                            anderen Webseiten zu diesem Thema umfassend informieren.

                            <br />
                            <br />

                            Grundsätzlich gilt: <br />

                            Die MAVERICK MEDIA CONSULTING GMBH beachtet die allgemeinen
                            Persönlichkeitsrechte der Nutzer. Aus diesem Grunde schützt
                            die MAVERICK MEDIA CONSULTING GMBH die personenbezogenen Daten
                            der Nutzer.

                            <br /><br />

                            Verantwortlich für die Erhebung und Verarbeitung von
                            personenbezogenen Daten ist die:
                            <br />

                            MAVERICK MEDIA CONSULTING GMBH <br />

                            ABC-Str. 21 20354 Hamburg
                            <br /><br />

                            Welche Daten werden erhoben und verwendet, wenn Sie die
                            Website besuchen? Sie können unsere Webseiten besuchen, ohne
                            Angaben zu Ihrer Person zu machen. Wir speichern lediglich
                            Zugriffsdaten in sogenannten Server-Logfiles, wie z. B. den
                            Namen der angeforderten Datei, Datum und Uhrzeit des Abrufs,
                            übertragene Datenmenge und den anfragenden Provider. Diese
                            Daten werden ausschließlich zur Sicherstellung eines
                            störungsfreien Betriebs der Seite und zur Verbesserung unseres
                            Angebots ausgewertet und erlauben uns keinen Rückschluss auf
                            Ihre Person.

                            <br /><br />
                            Zweck und Rechtsgrundlage der Datenverarbeitung <br />

                            Wir speichern Ihre Daten nur zur Erfüllung Ihrer Anfragen,
                            Erfüllung und Abwicklung der Verträge und zu eigenen
                            Werbezwecken. Die Verarbeitung erfolgt auf Basis der aktuellen
                            Datenschutzgesetze. Wenn Sie eine Anfrage haben, von uns ein
                            Angebot erstellen lassen oder mit uns einen Vertrag
                            abschließen, verarbeiten wir Ihre personenbezogenen Daten. Je
                            nach Art des Vertrages handelt es sich dabei um Ihre(n) Namen,
                            Vornamen, Anschrift, E-Mail-Adresse, Telefonnummer/Faxnummer,
                            ggfs. Geburtsdaten/Ort, Bankdaten, Umsatzsteuer-ID. Diese
                            Daten speichern und verarbeiten wir in unserer
                            Kundenverwaltung zur Erfüllung und Abwicklung des Vertrages
                            sowie zu eigenen Werbezwecken. Eine Weitergabe an sonstige
                            Dritte erfolgt nicht.

                            <br /><br />
                            Domainverwaltungen <br />

                            Auf dieser Seite finden Sie Hinweise über die Behandlung von
                            Daten, die Sie uns übermitteln. Die Grundlage dafür bietet die
                            ICANN Model Privacy Policy. <br /><br />

                            <br />
                            Auf unserer Website werden Sie ggfs. aufgefordert,
                            Informationen zu übermitteln

                            <br />

                            - zur Registrierung eines Domainnamens, <br />

                            - zur Aktualisierung eines früher registrierten
                            Domainnamens,<br />

                            - zur Bereitstellung von Web-/ und Mailspace oder<br />

                            im Zusammenhang mit anderen Dienstleistungen.<br /><br />

                            Um Domainnamen zu registrieren, müssen Sie den Namen der von
                            Ihnen gewünschten Domain, Ihren Namen und Ihre Anschrift (oder
                            Name und Anschrift der Personen, für die Sie die Registrierung
                            beantragen), technische Informationen zu dem Computer, auf dem
                            die Domain verwaltet werden soll sowie Name und Anschrift,
                            E-Mail-Adresse, Telefon- und – soweit vorhanden – Faxnummer
                            für die technischen und verwaltungs- sowie
                            rechnungstechnischen Kontakte zu der Domain angeben. Nach der
                            Registrierung der Domain sind Sie verpflichtet, diese Daten
                            auf dem neuesten Stand zu halten. Bei Fragen erbitten wir
                            Angaben zu Name, Rufnummer und E-Mail-Adresse.
                            <br /><br /><br />

                            An wen werden die Informationen der Domainverwaltungen
                            weitergeleitet?<br />

                            Wir leiten Daten zur Registrierung von Domain-Namen oder zu
                            Aktualisierung bzw. Berichtigung weiter an<br />

                            - unsere Mitarbeiter und Partner (AV-Subdienstleister) zur
                            Vertragserfüllung,<br />

                            den Administrator der zentralen Datenbank, der die von Ihnen
                            gewählte Top-Level-Domain betreibt (lesen Sie hierzu bitte die
                            TLD-Registrierungsbedingungen zur Feststellung der zuständigen
                            Registry),<br />

                            - Betreiber und Nutzer der zentralen Internet Whois-Datenbank
                            bezüglich Ihrer Domain-Registrierung,<br />

                            - die Internet Corporation for Assigned Names and Numbers
                            (ICANN), als technische Koordinierungsstelle für das Internet,
                            und Escrow-Agents, Prüfer, Whois-Service-Provider sowie<br />

                            gegebenenfalls von ICANN ersatzweise eingesetzte
                            Registrare.<br /><br /><br />

                            Informationen, die Sie uns im Zusammenhang mit Fragen zukommen
                            lassen, können an unsere Mitarbeiter und Berater sowie – auf
                            Anforderung – an ICANN weitergeleitet werden.
                            <br /><br />

                            Datenverarbeitung zu Werbezwecken<br />

                            Wir nutzen Ihre Daten nur für eigene Werbezwecke. Wir möchten
                            Sie über neue Produkte, unsere Serviceleistungen sowie
                            interessante Veranstaltungen informieren. Daher nutzen wir
                            Ihre Daten auch, um Ihnen über Ihre Käufe hinaus bestimmte
                            Produkte, Serviceleistungen oder Veranstaltungen zu empfehlen,
                            die Sie interessieren könnten. Die Auswertung der Kaufhistorie
                            und der Versand entsprechender Werbung per Post oder E-Mail an
                            Bestandskunden erfolgt unter Beachtung Ihrer berechtigten
                            Interessen nur für Produkte und Serviceleistungen der WWE
                            Media GmbH. Die Versendung per E-Mail erfolgt nur mit Ihrer
                            Einwilligung, die Sie mit Wirkung für die Zukunft jederzeit
                            widerrufen können. Newsletter werden an Sie nur auf Grund
                            einer gesonderten Einwilligung versendet. Weitere Infos zu
                            Ihren Rechten finden Sie unter der Überschrift
                            „Betroffenenrechte“.

                            <br /><br />

                            Datenerhebung und -verwendung zur Vertragsabwicklung und bei
                            Eröffnung eines Kundenkontos<br />

                            Wir erheben personenbezogene Daten, wenn Sie uns diese im
                            Rahmen Ihrer Bestellung, bei einer Kontaktaufnahme mit uns
                            (z.B. per Kontaktformular oder E-Mail) oder bei Eröffnung
                            eines Kundenkontos freiwillig mitteilen. Welche Daten erhoben
                            werden, ist aus den jeweiligen Eingabeformularen ersichtlich.
                            Wir verwenden die von ihnen mitgeteilten Daten ausschließlich
                            zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen. Nach
                            abschließender Beantwortung Ihrer Anfrage, vollständiger
                            Abwicklung des Vertrages oder Löschung Ihres Kundenkontos
                            werden Ihre Daten für die weitere Verwendung gesperrt und nach
                            Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen
                            gelöscht. Etwas anderes gilt nur, sofern Sie ausdrücklich in
                            eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir
                            uns eine darüberhinausgehende Datenverwendung vorbehalten, die
                            gesetzlich erlaubt ist und über die wir Sie nachstehend
                            informieren. Die Löschung Ihres Kundenkontos ist jederzeit
                            möglich und kann durch eine Nachricht an
                            datenschutz(at)wwe-media.de erfolgen.

                            <br /><br /><br />

                            Datenweitergabe zur Vertragserfüllung bei z. B. unseren
                            Shopsystemen<br />

                            Zur Vertragserfüllung geben wir Ihre Daten an das mit der
                            Lieferung beauftragten Versandunternehmen weiter, soweit dies
                            zur Lieferung bestellter Waren erforderlich ist. Zur
                            Abwicklung von Zahlungen geben wir die hierfür erforderlichen
                            Zahlungsdaten an das mit der Zahlung beauftragte
                            Kreditinstitut und ggf. von uns beauftragte
                            Zahlungsdienstleister weiter bzw. an den von Ihnen im
                            Bestellprozess ausgewählten Zahlungsdienst. Soweit Sie sich
                            für unseren Newsletter angemeldet haben, geben wir Ihre zur
                            Versendung des Newsletters erforderlichen Daten an einen
                            Dienstleister weiter, der mit dem Versand der Newsletter
                            beauftragt ist.

                            <br /><br />

                            Datenverwendung bei Anmeldung zum E-Mail-Newsletter<br />

                            Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir
                            die hierfür erforderlichen oder gesondert von Ihnen
                            mitgeteilten Daten, um Ihnen regelmäßig unseren
                            E-Mail-Newsletter zuzusenden. Die Abmeldung vom Newsletter ist
                            jederzeit möglich und kann entweder durch eine Nachricht an
                            die unten beschriebene Kontaktmöglichkeit oder über einen
                            dafür vorgesehenen Link im Newsletter erfolgen.

                            <br /><br />

                            E-Mail Newsletter / Speicherung von IP-Adressen<br />

                            Bei der Newsletter-Anmeldung wird die über das Webformular
                            eingegebenen E-Mail-Adresse mitsamt dem Zeitpunkt der Eingabe
                            und der IP-Adresse des Eingebenden zum Nachweis protokolliert.
                            Zum Abschluss der Newsletter-Anmeldung wird nach der Eingabe
                            ins Webformular eine E-Mail mit einem Bestätigungslink an die
                            angegebene E-Mail-Adresse verschickt. Auch die IP-Adresse des
                            Klicks auf diesen Bestätigungslink und die genaue Zeit der
                            Aktivierung des Bestätigungslinks werden zum Nachweis des
                            Double-Opt-In gespeichert.

                            <br /><br /><br />

                            Datenverwendung für Postwerbung und Ihr Widerspruchsrecht<br />

                            Darüber hinaus behalten wir uns vor, Ihren Vor- und Nachnamen,
                            Ihre Postanschrift und - soweit wir diese zusätzlichen Angaben
                            im Rahmen der Vertragsbeziehung von Ihnen erhalten haben -
                            Ihren Titel, akademischen Grad, Ihr Geburtsjahr und Ihre
                            Berufs-, Branchen- oder Geschäftsbezeichnung in
                            zusammengefassten Listen zu speichern und für eigene
                            Werbezwecke zu nutzen, z.B. zur Zusendung von interessanten
                            Angeboten und Informationen zu unseren Produkten per
                            Briefpost. Sie können der Speicherung und Verwendung Ihrer
                            Daten zu diesen Zwecken jederzeit durch eine Nachricht an die
                            unten beschriebene Kontaktmöglichkeit widersprechen.

                            <br /><br /><br />

                            Verwendung von Cookies<br />

                            Um den Besuch unserer Website attraktiv zu gestalten und die
                            Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir
                            auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt
                            es sich um kleine Textdateien, die auf Ihrem Endgerät
                            gespeichert werden und die bestimmte Einstellungen und Daten
                            zum Austausch mit unserem System über Ihren Browser speichern.
                            Einige der von uns verwendeten Cookies werden nach Ende der
                            Browser-Sitzung, also nach Schließen Ihres Browsers, wieder
                            gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben
                            auf Ihrem Endgerät und ermöglichen uns, Ihren Browser beim
                            nächsten Besuch wiederzuerkennen (persistente Cookies).
                            Cookies enthalten keine personenbezogenen Daten und können
                            somit keinem Nutzer direkt zugeordnet werden. Bitte beachten
                            Sie, dass bestimmte Cookies bereits gesetzt werden, sobald Sie
                            unsere Webseite betreten. Sie können Ihren Browser so
                            einstellen, dass Sie über das Setzen von Cookies informiert
                            werden und einzeln über deren Annahme entscheiden oder die
                            Annahme von Cookies für bestimmte Fälle oder generell
                            ausschließen. Bei der Nichtannahme von Cookies kann die
                            Funktionalität unserer Website eingeschränkt sein.

                            <br /><br />

                            Nachfolgend finden Sie Informationen zu den von uns
                            eingesetzten Cookies und der Einstellungsmöglichkeit Ihres
                            Browsers.

                            <br /><br />

                            1. Notwendige Cookies

                            <br /><br />

                            Diese Cookies sind notwendig, um den Betrieb unserer Webseite
                            zu ermöglichen. Dazu gehören bspw. Cookies, die es Ihnen
                            ermöglichen, sich in den Kundebereich einzuloggen, oder etwas
                            in den Warenkorb zu legen.

                            <br /><br />
                            2. Analytische- / Leistungs – Cookies

                            <br /><br />

                            Diese Cookies ermöglichen es anonymisierte Daten über das
                            Nutzungsverhalten unserer Besucher zu sammeln. Diese werden
                            von uns dann ausgewertet, um bspw. die Funktionalität der
                            Webseite zu verbessern und Ihnen interessante Angebote
                            anzuzeigen.

                            <br /><br />

                            3. Funktional - Cookies

                            <br /><br />

                            Diese Cookies werden für bestimmte Funktionalitäten unserer
                            Webseite verwendet, z.B. um einen besseren Navigationsfluss
                            auf unsere Webseite vorzuschlagen, Ihnen personalisierte und
                            relevante Informationen aufzuzeigen (bspw.
                            „interessensbasierte Werbeanzeigen“)

                            <br /><br />

                            4. Targeting - Cookies
                            <br /><br />

                            Diese Cookies erfassen Ihren Besuch auf unserer Website, die
                            Seiten, die Sie besucht haben und die Links, die Sie
                            ausgeführt haben. Wir werden diese Informationen benutzen, um
                            unsere Website und die Werbung, die Sie angezeigt bekommen,
                            auf Ihre Interessen zuzuschneiden.

                            <br /><br />

                            5. Third-Party-Cookies

                            <br /><br />
                            Diese Cookies einiger unserer Werbepartner, helfen dabei das
                            Internetangebot und unsere Webseite für Sie interessanter zu
                            gestalten. Daher werden bei Ihrem Besuch auf unserer Webseite
                            auch Cookies von Partnerunternehmen auf Ihrer Festplatte
                            gespeichert. Hierbei handelt es sich um temporäre Cookies, die
                            sich automatisch nach der vorgegebenen Zeit löschen. Cookies
                            von Partnerunternehmen werden in der Regel nach wenigen Tagen
                            oder bis zu 24 Monaten gelöscht, im Einzelfall auch nach
                            mehreren Jahren. Auch die Cookies unserer Partnerunternehmen
                            enthalten keine personenbezogenen Daten. Es werden lediglich
                            unter einem User-ID Pseudonym Daten erhoben. Diese pseudonymen
                            Daten werden zu keinem Zeitpunkt mit Ihren personenbezogenen
                            Daten zusammengeführt.

                            <br /><br />

                            Wie kann ich die Cookie Einstellungen meines Browsers
                            konfigurieren?<br />

                            Jeder Browser unterscheidet sich in der Art, wie er die
                            Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü
                            jedes Browsers beschrieben, welches Ihnen erläutert, wie Sie
                            Ihre Cookie-Einstellungen ändern können. Diese finden Sie für
                            die jeweiligen Browser z.B. auch unter den folgenden Links:<br /><br />

                            Internet Explorer™:
                            http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies

                            <br /><br />
                            Safari™:
                            http://apple-safari.giga.de/tipps/cookies-in-safari-aktivieren-blockieren-loeschen-so-geht-s/

                            <br /><br />
                            Chrome™:
                            http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647

                            <br /><br />

                            Firefox™
                            https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
                            <br /><br />

                            Einsatz von Revive Adserver zur Auslieferung on Werbebannern

                            <br /><br />

                            Wir verwenden das openScource System revive um Banner
                            auszuliefern und zu steuern. Eine Steuerung umfasst auch, dass
                            das selbe Banner nicht immer wieder ausgeliefert werden soll.
                            Hierzu werden Cookies eingesetzt. Es werden ausschließlich
                            allgemeine Werbeauslieferungen auf definierten Werbeplätzen
                            durchführt.

                            <br /><br />

                            Einsatz von Matomo zur Webanalyse
                            <br /><br />

                            Wir verwenden die Open-Source-Software Matomo zur Analyse und
                            statistischen Auswertung der Nutzung der Website. Hierzu
                            werden Cookies eingesetzt Die durch den Cookie erzeugten
                            Informationen über die Websitenutzung werden an unsere Server
                            übertragen und in pseudonymen Nutzungsproﬁlen zusammengefasst.
                            Die Informationen werden verwendet, um die Nutzung der Website
                            auszuwerten und um eine bedarfsgerechte Gestaltung unserer
                            Website zu ermöglichen. Eine Weitergabe der Informationen an
                            Dritte erfolgt nicht.

                            <br /><br />

                            Es wird in keinem Fall die IP-Adresse mit anderen den Nutzer
                            betreffenden Daten in Verbindung gebracht, Die IP-Adressen
                            werden anonymisiert, so dass eine Zuordnung nicht möglich ist
                            (IP-Masking).

                            <br /><br />

                            Wenn Sie in Ihrem Browser eine „not Tracking“ Einstellung
                            vorgenommen haben wird unser System das respektieren und sie
                            nicht tracken. Anderenfalls wird ihr Besuch dieser Webseite
                            aktuell von unserer Matomo Webanalyse erfasst. Klicken Sie
                            hier (Bitte nicht tracken!), damit Ihr Besuch nicht mehr
                            erfasst wird.

                            <br /><br />

                            Einsatz von Google Analytics zur Webanalyse

                            <br /><br />

                            Diese Website benutzt oder wird in Kürze Google Analytics
                            benutzen, einen Webanalysedienst der Google Inc.
                            (www.google.de), (im Folgenden „Analysetools“). Diese
                            Analysetools setzen Cookies, die eine Analyse der Benutzung
                            der Website ermöglichen. Die durch den Cookie erzeugten
                            Informationen über die Benutzung dieser Website werden in der
                            Regel an einen Server von Google in den USA übertragen und
                            dort gespeichert. Durch die Aktivierung der IP-Anonymisierung
                            auf dieser Webseite wird dabei die IP-Adresse vor der
                            Übermittlung innerhalb der Mitgliedstaaten der Europäischen
                            Union oder in anderen Vertragsstaaten des Abkommens über den
                            Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen
                            wird die volle IP-Adresse an einen Server von Google in den
                            USA übertragen und dort gekürzt. Im Auftrag des Betreibers
                            dieser Website wird Google die Informationen benutzen, um die
                            Nutzung der Website auszuwerten, um Reports über die
                            Websiteaktivitäten zusammenzustellen und um weitere mit der
                            Websitenutzung und der Internetnutzung verbundene
                            Dienstleistungen uns gegenüber zu erbringen. Die im Rahmen von
                            Google Analytics von Ihrem Browser übermittelte anonymisierte
                            IP-Adresse wird nicht mit anderen Daten von Google
                            zusammengeführt. Sie können die Speicherung der Cookies durch
                            eine entsprechende Einstellung Ihrer Browser-Software
                            verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
                            diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
                            Website vollumfänglich werden nutzen können.

                            <br /><br />

                            Sie können darüber hinaus die Erfassung der durch das Cookie
                            erzeugten und auf Ihre Nutzung der Website bezogenen Daten
                            (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung
                            dieser Daten durch Google verhindern, indem sie das unter dem
                            folgenden Link verfügbare Browser-Plugin herunterladen und
                            installieren: http://tools.google.com/dlpage/gaoptout?hl=de

                            <br /><br /><br />

                            Google Maps

                            <br /><br />

                            Diese Webseite verwendet die Google Maps API, um geographische
                            Informationen visuell darzustellen. Bei der Nutzung von Google
                            Maps werden von Google (Google Inc., 1600 Amphitheatre
                            Parkway, Mountain View, California, 94043) auch Daten über die
                            Nutzung der Maps-Funktionen durch Besucher der Webseiten
                            erhoben, verarbeitet und genutzt. Nähere Informationen über
                            die Datenverarbeitung durch Google können Sie den
                            Datenschutzhinweisen von Google entnehmen, die Sie unter
                            www.google.com/privacypolicy.html abrufen können.

                            <br /><br /><br />

                            Einsatz von Google Adwords Conversion Tracking

                            <br /><br />

                            Diese Website ist oder wird dem Online-Werbeprogramm „Google
                            AdWords“ der Google Inc. (www.google.com) angeschlossen.
                            Google verwendet Cookies, damit Unternehmen, die Google für
                            die Schaltung von Anzeigen bezahlen (Inserenten), feststellen
                            können, wie viele Personen, die auf ihre Anzeigen klicken,
                            anschließend ihre Produkte kaufen (Conversion-Tracking). Das
                            Cookie für das Conversion-Tracking wird nur dann auf Ihrem
                            Endgerät gespeichert, wenn Sie auf eine von Google geschaltete
                            Anzeige klicken, bei der sich der Inserent für das
                            Conversion-Tracking entschieden hat. Diese Cookies laufen nach
                            90 Tagen ab und enthalten keine Informationen, durch die Sie
                            persönlich identifiziert werden können. Falls Sie bestimmte
                            Seiten der Website eines Inserenten besuchen und das Cookie
                            noch nicht abgelaufen ist, können Google und der Inserent
                            erkennen, dass Sie auf die Anzeige geklickt haben und zu
                            dieser Seite weitergeleitet wurden. Jeder Inserent erhält ein
                            anderes Cookie. Cookies können somit nicht über die Websites
                            von Inserenten nachverfolgt werden. Wenn Sie Cookies für
                            Conversion-Tracking deaktivieren möchten, können Sie Ihren
                            Browser so einstellen, dass Cookies von der Domain
                            „googleadservices.com“ blockiert werden.

                            <br /><br /><br />

                            Bewerberdatendatenschutz

                            <br /><br />

                            Wir erheben und verarbeiteten personenbezogenen Daten von
                            Bewerbern zum Zwecke der Abwicklung des Bewerbungsverfahrens.
                            Die Verarbeitung kann auch digital erfolgen. Das ist immer
                            dann der Fall, wenn ein Bewerber uns Bewerbungsunterlagen
                            digital, beispielsweise per E-Mail oder über ein auf unserer
                            Webseite mögliches eingesetztes Webformular, zusendet.
                            Schließen wir mit dem Bewerber einen Anstellungsvertrag,
                            werden die übermittelten Daten zum Zwecke der Abwicklung des
                            Beschäftigungsverhältnisses unter Beachtung der gesetzlichen
                            Vorschriften gespeichert. Wird mit dem Bewerber kein
                            Anstellungsvertrag geschlossen, so werden die
                            Bewerbungsunterlagen vier Monate nach Bekanntgabe der
                            Absageentscheidung automatisch gelöscht, sofern einer Löschung
                            keine sonstigen berechtigten Interessen wie beispielsweise
                            eine Beweispflicht in einem Verfahren nach dem Allgemeinen
                            Gleichbehandlungsgesetz (AGG), von unserer Seite
                            entgegenstehen.

                            <br /><br /><br />

                            Betroffenenrechte

                            <br /><br />

                            Sie haben das Recht:

                            <br /><br />

                            -gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
                            personenbezogenen Daten zu Verlangen. Insbesondere können Sie
                            Auskunft über die Verarbeitungszwecke, die Kategorie der
                            personenbezogenen Daten, die Kategorien von Empfängern,
                            gegenüber denen Ihre Daten offengelegt wurden oder werden, die
                            geplante Speicherdauer, das Bestehen eines Rechts auf
                            Berichtigung, Löschung, Einschränkung der Verarbeitung oder
                            Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft
                            ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie
                            über das Bestehen einer automatisierten Entscheidungsfindung
                            einschließlich Profiling und ggf aussagekräftigen
                            Informationen zu deren Einzelheiten verlangen;

                            <br /><br />

                            - gemäß Art. 16 DSGVO unverzüglich die Berichtigung
                            unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten
                            personenbezogenen Daten zu verlangen;

                            <br /><br />

                            -gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
                            personenbezogenen Daten zu verlangen, soweit nicht die
                            Verarbeitung zur Ausübung des Rechts auf freie
                            Meinungsäußerung und Information, zur Erfüllung einer
                            rechtlichen Verpflichtung, aus Gründen des öffentlichen
                            Interesses oder zur Geltendmachung, Ausübung oder Verteidigung
                            von Rechtsansprüchen erforderlich ist;

                            <br /><br />
                            -gemäß Art, 18 DSGVO die Einschränkung der Verarbeitung Ihrer
                            personenbezogenen Daten zu verlangen, soweit die Richtigkeit
                            der Daten von Ihnen bestritten wird, die Verarbeitung
                            unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die
                            Daten nicht mehr benötigen, Sie jedoch diese zur
                            Geltendmachung, Ausübung oder Verteidigung von
                            Rechtsansprüchen benötigen oder Sie gemäß Art. Z1 DSGVO
                            Widerspruch gegen die Verarbeitung eingelegt haben;

                            <br /><br />

                            -gemäß Art, 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
                            bereitgestellt haben, in einem strukturierten, gängigen und
                            maschinenlesebaren Format zu erhalten oder die Übermittlung an
                            einen anderen Verantwortlichen zu verlangen;
                            <br /><br />

                            -gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
                            jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge,
                            dass wir die Datenverarbeitung, die auf dieser Einwilligung
                            beruhte, für die Zukunft nicht mehr fortführen dürfen und

                            <br /><br />

                            -gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu
                            beschweren. In der Regel können Sie sich hierfür an die
                            Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder
                            Arbeitsplatzes oder unseres Firmensitzes wenden.

                            <br /><br /><br />

                            Widerspruchsrecht

                            <br /><br />

                            Sofern Ihre personenbezogenen Daten auf Grundlage von
                            berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO
                            verarbeitet werden, haben Sie das Recht, gemäß Art. Z1 DSGVO
                            Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
                            Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus
                            Ihrer besonderen Situation ergeben oder sich der Widerspruch
                            gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein
                            generelles Widerspruchsrecht, das ohne Angabe einer besonderen
                            Situation von uns umgesetzt wird.

                            <br /><br />

                            Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht
                            Gebrauch machen, genügt eine E-Mail an
                            datenschutz@wwe-media.de

                            <br /><br />

                            Datensicherheit
                            <br /><br />

                            Wir verwenden innerhalb des Website-Besuchs das verbreitete
                            SSL-Verfahren (Secure Socket Layer) in Verbindung mit der
                            jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser
                            unterstützt wird. In der Regel handelt es sich dabei um eine
                            256 Bit Verschlüsselung, Falls Ihr Browser keine 256-Bit
                            Verschlüsselung unterstützt, greifen wir stattdessen auf
                            128-Bit V3 Technologie zurück. Ob eine einzelne Seite unseres
                            Internetauftrittes verschlüsselt übertragen wird, erkennen Sie
                            an der geschlossenen Darstellung des Schüssel- beziehungsweise
                            Schloss-Symbols in der unteren Statusleiste Ihres Browsers,

                            <br /><br />

                            Wir bedienen uns im Übrigen geeigneter technischer und
                            organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
                            zufällige oder vorsätzliche Manipulationen, teilweisen oder
                            vollständigen Verlust, Zerstörung oder gegen den unbefugten
                            Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen
                            werden entsprechend der technologischen Entwicklung
                            fortlaufend verbessert.

                            <br /><br /><br />

                            Auskunftsrecht und Kontaktmöglichkeit

                            <br /><br />
                            Sie haben ein Recht auf unentgeltliche Auskunft über die bei
                            uns zu Ihrer Person gespeicherten Daten sowie ggf. ein Recht
                            auf Berichtigung, Sperrung oder Löschung dieser Daten. Bei
                            Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer
                            personenbezogenen Daten, bei Auskünften, Berichtigung,
                            Sperrung oder Löschung von Daten sowie Widerruf ggf. erteilter
                            Einwilligungen oder Widerspruch gegen eine bestimmte
                            Datenverwendung senden Sie uns eine Mail an
                            datenschutz@wwe-media.de.

                            <br /><br />

                            Oder per Post an:
                            <br /><br />

                            WWE Media GmbH<br />

                            ABC-Str. 21
                            <br />
                            20354 Hamburg
                            <br /><br /><br />

                            Aktualisierung und Änderung

                            <br /><br />

                            Die Datenschutzerklärung muss von Zeit zu Zeit an die
                            tatsächlichen Verhältnisse und an die Rechtslage angepasst
                            werden. Bitte überprüfen Sie jeweils die Datenschutzerklärung
                            bevor Sie unser Angebot nutzen, um bei möglichen Änderungen
                            oder Aktualisierungen auf dem neusten Stand zu sein.
                            <br /><br /><br />
                            Stand der Datenschutzerklärung 25.05.2018
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>
<script>

import BannerView from '@/components/General/BannerView.vue';

import MainLayout from '../components/MainLayout.vue'
 
export default {
    name: 'DatenschutzView',
    components: {
    MainLayout,
    BannerView
}
}
</script>

<style>
    .blog-padding {
        padding-bottom: 75px !important;
    }
</style>