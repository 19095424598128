<template>
    <MainLayout>
        <BannerView title="Kontakt" breadcrumb="Home" breadcrumbItem="kontakt" />
        <ContactInfo />
        <MaverickSectionView 
            title="WIR SIND MAVERICK" style="margin-top: 100px;"
            contentH4="Die MAVERICK finden Sie in der Englischen Planke 2, das ist in 20459 Hamburg. Die Hausnummer 2 finden Sie gleich rechts an der Ecke Ludwig-Erhard-Straße und Englische Planke. Es ist das weiße Bürogebäude. Die MAVERICK befindet sich im Erdgeschoß. Das Haus wird gerne übersehen, weil die St. Michaelis Kirche, oder kurz unser Michel sehr beeindrucket direkt vor einem erscheint. In der Nähe befinden sich Parkhäuser, am besten nehmen Sie das vom Michel." />
        <ContactForm />
        <mapSection />
    </MainLayout>
</template>

<script>
import BannerView from '@/components/General/BannerView.vue';
import MaverickSectionView from '../components/General/MaverickSectionView.vue'

import ContactInfo from '@/components/Contact/ContactInfo.vue';
import ContactForm from '@/components/Contact/ContactForm.vue';
import mapSection from '@/components/Contact/mapSection.vue';

import MainLayout from '../components/MainLayout.vue'

export default {
    name: 'ContactUsView',
    components: {
        MainLayout,
        BannerView,
        MaverickSectionView,
        ContactInfo,
        ContactForm,
        mapSection
    },
    data() {
        return {
            showRecaptcha: true,
            loadingTimeout: 30000
        }
    },
}
</script>

<style>

.amin {
    background-image: url(../assets/images/bg/testi-bg4.jpg) !important;
}
</style>