<template>
    <div
        class="section maverick-contact-section maverick-contact-section-02 maverick-contact-section-03 section-padding-02 amin noselect nodrag">
        <div class="container">
            <div class="contact-wrap">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="contact-form">
                            <div class="contact-form-wrap">
                                <div class="heading-wrap text-center">
                                    <span class="sub-title">Angebot anfordern</span>
                                    <h3 class="title">Wie können wir Ihnen helfen?</h3>
                                </div>
                                <div v-if="alert.show" class="alert"
                                    :class="{ 'alert-success': alert.type === 'success', 'alert-danger': alert.type === 'error' }"
                                    role="alert">
                                    {{ alert.message }}
                                </div>
                                <form @submit.prevent="submitForm">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="single-form">
                                                <input v-model="form.name" type="text" placeholder="Name *" name="name"
                                                    required>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="single-form">
                                                <input v-model="form.email" type="email" placeholder="E-Mail *" name="email"
                                                    required>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="single-form">
                                                <input v-model="form.subject" type="text" placeholder="Betreff *"
                                                    name="subject" required>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="single-form">
                                                <textarea v-model="form.message" placeholder="Eine Nachricht schreiben"
                                                    name="message" required></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="recaptcha-wrapper recaptcha-center">
                                                <input v-model="form['g-recaptcha-response']" type="hidden"
                                                    name="g-recaptcha-response" required>

                                                <VueRecaptcha requierd sitekey="6LdLWzUpAAAAAP_P7Z-DCgSLqSW9eW1tZGJv2vEk"
                                                    @verify="onCaptchaVerified" @expired="onCaptchaExpired">
                                                </VueRecaptcha>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-btn">
                                                <button class="btn" type="submit">Nachricht senden</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import VueRecaptcha from 'vue3-recaptcha2';

export default {
    name: 'ContactForm',
    components: { VueRecaptcha },
    data() {
        return {
            form: {
                name: '',
                email: '',
                subject: '',
                message: '',
                'g-recaptcha-response': '',
            },
            alert: {
                show: false,
                type: '',
                message: ''
            }
        };
    },
    methods: {
        onCaptchaVerified(response) {
            this.form['g-recaptcha-response'] = response;
        },
        onCaptchaExpired() {
            console.log('reCAPTCHA expired');
        },

        async submitForm() {
            if (this.form['g-recaptcha-response']) {
                try {
                    await axios.post('/send-email.php',this.form);
                    this.alert = {
                        show: true,
                        type: 'success',
                        message: 'Ihre Nachricht wurde erfolgreich gesendet.'
                    };
                    setTimeout(() => {
                        this.alert.show = false;
                        this.resetForm();
                    },4000);
                } catch (error) {
                    this.alert = {
                        show: true,
                        type: 'error',
                        message: 'Es gab ein Problem beim Senden Ihrer Nachricht.'
                    };
                    setTimeout(() => {
                        this.alert.show = false;
                    },4000);
                }
            } else {
                this.alert = {
                    show: true,
                    type: 'error',
                    message: 'Bitte bestätigen Sie das reCAPTCHA.'
                };
                setTimeout(() => {
                    this.alert.show = false;
                },3000);
            }
        },

        resetForm() {
            this.form = {
                name: '',
                email: '',
                subject: '',
                message: '',
                'g-recaptcha-response': '',
            };
        }



    }
}
</script>

<style>
.recaptcha-center {
    justify-content: center;
    display: flex;
    margin-top: 3%;
}
</style>