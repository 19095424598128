<template>
    <div class="section maverick-choose-us-section maverick-choose-us-section-04 section-padding noselect nodrag">
        <div class="container">
            <div class="choose-us-wrap">
                <div class="choose-us-content-wrap">
                    <div class="row">
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/ser-icon9.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a href="#">eCommerce projekte</a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie mit
                                        einem gesamten Team zusammenbringen
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/counter-3.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a href="#">Finanzprodukte</a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie mit
                                        einem gesamten Team zusammenbringen
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/ser-icon10.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a href="#">Digitalisierungs prozesse</a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie mit
                                        einem gesamten Team zusammenbringen
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/ser-icon12.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a href="#">Online Portale</a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie mit
                                        einem gesamten Team zusammenbringen
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/counter-1.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a href="#">Onlineshop</a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie mit
                                        einem gesamten Team zusammenbringen
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/counter-2.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a href="#">CMS</a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie mit
                                        einem gesamten Team zusammenbringen
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/ser-icon21.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a href="#">App programmierung</a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie mit
                                        einem gesamten Team zusammenbringen
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="choose-us-item-02">
                                <div class="choose-us-img">
                                    <img src="../../assets/images/ser-icon22.png" alt="">
                                </div>
                                <div class="choose-us-content">
                                    <h3 class="title"><a href="#">VR programmierung</a></h3>
                                    <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie mit
                                        einem gesamten Team zusammenbringen
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OurService'
}
</script>
<style></style>