<template>
    <div class="section maverick-counter-section-03 maverick-counter-section-04 noselect nodrag">
        <div class="container">
            <div class="counter-wrap test5" id="counter-section">
                <div class="row">
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-counter-02 text-center">
                            <span>354+</span>
                            <p>Abgeschlossene Projekte</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-counter-02 text-center">
                            <span>119+</span>
                            <p>Roboter-Automatisierung</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-counter-02 text-center">
                            <span>99%</span>
                            <p>Webseitenanalyse</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="single-counter-02 text-center">
                            <span>321+</span>
                            <p>Erledigte Kundenunterstützung</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'KundenCounterView',
    mounted() {
        this.createObserver();
    },
    methods: {
        animateValue(obj,start,end,duration) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration,1);
                obj.innerHTML = Math.floor(progress * (end - start) + start) + (obj.dataset.suffix || '');
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        },
        createObserver() {
            const observer = new IntersectionObserver((entries,observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const counters = entry.target.querySelectorAll('.single-counter-02 span');
                        counters.forEach(counter => {
                            const endValue = parseInt(counter.textContent,10);
                            counter.dataset.suffix = counter.textContent.match(/\D+$/)?.[0] || '';
                            this.animateValue(counter,0,endValue,2000);
                        });
                        observer.unobserve(entry.target);
                    }
                });
            },{ threshold: 0.5 });

            const section = document.getElementById('counter-section');
            if (section) {
                observer.observe(section);
            }
        }
    }
}
</script>


<style>
.test5 {
    background-image: url(../../assets/images/bg/counter-bg2.jpg);
}
</style>