<template>
        <div class="section maverick-skill-section-02 maverick-skill-section-03 section-padding noselect nodrag">
            <div class="container">
                <div class="skill-wrap">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="skill-left">
                                <div class="section-title">
                                    <h2 class="title">Für Ihren Erfolg vorbereitet, bieten wir wirklich herausragende
                                        IT-Lösungen</h2>
                                </div>
                                <div class="about-list">
                                    <ul>
                                        <li>
                                            <span class="about-icon"><i class="fas fa-check"></i></span>
                                            <span class="about-text">Wir konzentrieren uns stets auf technische
                                                Exzellenz</span>
                                        </li>
                                        <li>
                                            <span class="about-icon"><i class="fas fa-check"></i></span>
                                            <span class="about-text"> Wohin Sie auch gehen, wir bringen Ideen und
                                                Begeisterung</span>
                                        </li>
                                        <li>
                                            <span class="about-icon"><i class="fas fa-check"></i></span>
                                            <span class="about-text">Wir sind Berater, Wegweiser und Partner für
                                                Marken</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="about-author-info-wrap">
                                    <div class="about-author">
                                        <img src="../../assets/images/sign.png" alt="">
                                        <h3 class="name">Stefan Muhlack</h3>
                                        <span class="designation">CEO, Maverick</span>
                                    </div>
                                    <div class="about-info">
                                        <p>Rufen Sie an, um jede Frage zu stellen</p>
                                        <h3 class="number">040 6963 55 630</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="skill-right">
                                <p class="text">Beschleunigen Sie Innovation mit erstklassigen Technikteams. Wir bringen Sie
                                    mit einem kompletten Remote-Team aus unglaublich talentierten Freiberuflern zusammen, um
                                    all Ihre Bedürfnisse in der Softwareentwicklung zu erfüllen, einschließlich
                                    Cloud-Building, Infrastruktur, Netzwerk usw. Wir legen großen Wert auf die Bedürfnisse
                                    Ihres Unternehmens, um Lösungen zu finden, die am besten zu Ihren Anforderungen passen
                                    und diese perfekt umsetzen.</p>
                                <div class="counter-bar">
                                    <div class="skill-item">
                                        <span class="title">IT-Management</span>
                                        <div class="skill-bar">
                                            <div class="bar-inner">
                                                <div class="bar progress-line" data-width="95">
                                                    <span class="skill-percent"><span class="counter">95</span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="skill-item">
                                        <span class="title">Datensicherheit</span>
                                        <div class="skill-bar">
                                            <div class="bar-inner">
                                                <div class="bar progress-line color-1" data-width="87">
                                                    <span class="skill-percent"><span class="counter">87</span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="skill-item">
                                        <span class="title">Informationstechnologie</span>
                                        <div class="skill-bar">
                                            <div class="bar-inner">
                                                <div class="bar progress-line color-1" data-width="100">
                                                    <span class="skill-percent"><span class="counter">100</span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: 'AboutSkillWrap'
}
</script>
<style>
    
</style>