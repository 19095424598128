<template>
<div class="section maverick-skill-section-02 section-padding test9 noselect nodrag">
            <div class="container">
                <div class="skill-wrap">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="skill-left">
                                <div class="section-title">
                                    <h2 class="title">"Wir bereiten Ihren Erfolg vor und bieten wirklich herausragende IT-Lösungen.
                                    </h2>
                                </div>
                                <div class="experience-wrap">
                                    <div class="experience">
                                        <h2 class="number">25</h2>
                                        <span>Jahre <br> Erfahrung</span>
                                    </div>
                                    <div class="experience-text">
                                        <p>Beschleunigen Sie Innovation mit erstklassigen Technikteams. Wir finden für Sie ein komplettes Remote-Team aus herausragenden freiberuflichen Talenten für all Ihre Softwareentwicklungsbedürfnisse.</p>
                                        <a class="learn-more" href="#">Erhalten Sie mehr Informationen über uns<i
                                                class="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="skill-right">
                                <div class="counter-bar">
                                    <div class="skill-item">
                                        <span class="title"> IT-Management</span>
                                        <div class="skill-bar">
                                            <div class="bar-inner">
                                                <div class="bar progress-line color-1" data-width="80">
                                                    <span class="skill-percent"><span class="counter">80</span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="skill-item">
                                        <span class="title">Datensicherheit</span>
                                        <div class="skill-bar">
                                            <div class="bar-inner">
                                                <div class="bar progress-line color-1" data-width="95">
                                                    <span class="skill-percent"><span class="counter">95</span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="skill-item">
                                        <span class="title">Informationstechnologie </span>
                                        <div class="skill-bar">
                                            <div class="bar-inner">
                                                <div class="bar progress-line color-1" data-width="80">
                                                    <span class="skill-percent"><span class="counter">80</span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="skill-item">
                                        <span class="title">Technologieberater</span>
                                        <div class="skill-bar">
                                            <div class="bar-inner">
                                                <div class="bar progress-line color-1" data-width="90">
                                                    <span class="skill-percent"><span class="counter">90</span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'WhyUsSectionNumber2'
}
</script>

<style>
    
</style>