<template>
    <MainLayout>
        <BannerView title="Über uns" breadcrumb="Home" breadcrumb-item="Über uns" />
        <AboutContentWrap />
        <KundenCounterView />
        <ChooseUsWrap />
        <AboutSkillWrap />
        <MaverickSectionView 
            title="WIR SIND MAVERICK" style="margin-top: 100px;"
            contentH4="Die MAVERICK finden Sie in der Englischen Planke 2, das ist in 20459 Hamburg. Die Hausnummer 2 finden Sie gleich rechts an der Ecke Ludwig-Erhard-Straße und Englische Planke. Es ist das weiße Bürogebäude. Die MAVERICK befindet sich im Erdgeschoß. Das Haus wird gerne übersehen, weil die St. Michaelis Kirche, oder kurz unser Michel sehr beeindrucket direkt vor einem erscheint. In der Nähe befinden sich Parkhäuser, am besten nehmen Sie das vom Michel." />
        <BrandsSectionView />
        <AboutCta />
    </MainLayout>
</template>

<script>
import AboutContentWrap from '@/components/About/AboutContentWrap.vue'
import ChooseUsWrap from '@/components/About/ChooseUsWrap.vue'
import AboutSkillWrap from '@/components/About/AboutSkillWrap.vue'
import AboutCta from '@/components/About/AboutCta.vue'
import KundenCounterView from '@/components/About/KundenCounterView.vue';

import BannerView from '@/components/General/BannerView.vue';
import BrandsSectionView from '@/components/General/BrandsSectionView.vue';
import MaverickSectionView from '@/components/General/MaverickSectionView.vue'

import MainLayout from '@/components/MainLayout.vue';

export default {
    name: 'AboutUsView',
    components: {
        MainLayout,
        BannerView,
        BrandsSectionView,
        KundenCounterView,
        MaverickSectionView,
        AboutContentWrap,        
        ChooseUsWrap,
        AboutSkillWrap,
        AboutCta,
    },
    mounted() {
        this.initializeProgressBars();
    },
    methods: {
        initializeProgressBars() {
            const progressBars = this.$el.querySelectorAll('.progress-line');
            progressBars.forEach(bar => {
                const width = bar.getAttribute('data-width');
                bar.style.width = '0%';
                setTimeout(() => {
                    bar.style.width = `${width}%`;
                },0);
            });
        }
    }
}
</script>

<style scoped>
.progress-line {
    width: 0;
    transition: width 2s ease;
}

.test4 {
    background-image: url(../assets/images/bg/choose-us-bg3.jpg);
}

.test5 {
    background-image: url(../assets/images/bg/counter-bg2.jpg);

}

.test6 {
    background-image: url(../assets/images/bg/cta-bg.jpg);
}

.test7 {
    background-image: url(../assets/images/bg/team-bg1.jpg);
}

.subtitle-team {
    font-size: 20px;
}

.section-padding-test {
    padding-top: 0px !important;
}

.title-33 {
    font-size: 33px !important;
}</style>




