<template>
    <div class="main-wrapper noselect nodrag">
        <HeaderSectionView />
        <slot></slot>
        <div class="progress-wrap active-progress" v-if="showBackToTop" @click="toTop">
            <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
        </div>
        <FooterSectionView />
    </div>
</template>

<script>
import HeaderSectionView from '../components/Layout/HeaderSectionView.vue'
import FooterSectionView from '../components/Layout/FooterSectionView.vue'
export default {
    name: 'MainLayout',
    components: {
        HeaderSectionView,FooterSectionView
    },
    data() {
      return {
        scTimer: 0,
        scY: 0,
        showBackToTop: false
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          this.showBackToTop = this.scY > 100;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 500);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
    },
}
</script>
<style></style>
