<template>
    <div class="section maverick-cta-section-04 maverick-cta-section-06 maverick-cta-section-07 noselect nodrag">
        <div class="container">
            <div class="cta-wrap  test8">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="cta-left">
                            <div class="section-title">
                                <h2 class="title white">Um weitere Informationen anzufordern, kontaktieren Sie uns</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="cta-info text-center">
                            <div class="cta-icon">
                                <img src="../../assets/images/cta-icon2.png" alt="">
                            </div>
                            <div class="cta-text">
                                <p>Rufen Sie uns an bei jeglichen Anfragen</p>
                                <h3 class="number">+49 40 696355630</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ServiceCta'
}
</script>
<style>
    .test8 {
    background: url(../../assets/images/bg/cta-bg5.jpg) !important;
    z-index: 1000;
}
</style>