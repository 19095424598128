<template>
  <div class="section maverick-brand-section noselect nodrag">
    <div class="container">
      <div class="brand-wrapper section-padding text-center">
        <h3 class="brand-title">Treten Sie vor, um einer von <span>49,494</span> erfolgreichen Kunden von Mitech zu
          werden.</h3>
        <div class="brand-active">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <swiper :slides-per-view="3" :space-between="30" class="case-study-active">
                <swiper-slide>
                  <div class="swiper-slide single-brand">
                    <img :src="require('@/assets/images/brand/brand-1.png')" alt="Brand">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="swiper-slide single-brand">
                    <img :src="require('@/assets/images/brand/brand-2.png')" alt="Brand">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="swiper-slide single-brand">
                    <img :src="require('@/assets/images/brand/brand-3.png')" alt="Brand">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="swiper-slide single-brand">
                    <img :src="require('@/assets/images/brand/brand-4.png')" alt="Brand">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="swiper-slide single-brand">
                    <img :src="require('@/assets/images/brand/brand-5.png')" alt="Brand">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="swiper-slide single-brand">
                    <img :src="require('@/assets/images/brand/brand-2.png')" alt="Brand">
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper,SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
  name: 'BrandsSectionView',
  components: {
    Swiper,SwiperSlide
  }
}
</script>
<style></style>