<template>
<div class="section maverick-choose-us-section maverick-choose-us-section-03 section-padding noselect nodrag">
            <div class="container">
                <div class="choose-us-wrap">
                    <div class="section-title text-center">
                        <h3 class="sub-title">GRUND, UNS ZU WÄHLEN</h3>
                        <h2 class="title">Wir bieten wirklich herausragende IT-Lösungen.</h2>
                    </div>
                    <div class="choose-us-content-wrap">
                        <div class="row">
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="choose-us-item-02">
                                    <div class="choose-us-img">
                                        <img src="../../assets/images/ser-icon9.png" alt="">
                                    </div>
                                    <div class="choose-us-content">
                                        <h3 class="title"><a><router-link to="/service">Infrastruktur <br> technologie </router-link></a></h3>
                                        <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie einem kompletten Team zuordnen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="choose-us-item-02">
                                    <div class="choose-us-img">
                                        <img src="../../assets/images/counter-3.png" alt="">
                                    </div>
                                    <div class="choose-us-content">
                                        <h3 class="title"><a><router-link to="/service">Hochprofessionelle <br> Teammitglieder</router-link></a></h3>
                                        <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie einem kompletten Team zuordnen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="choose-us-item-02">
                                    <div class="choose-us-img">
                                        <img src="../../assets/images/ser-icon10.png" alt="">
                                    </div>
                                    <div class="choose-us-content">
                                        <h3 class="title"><a><router-link to="/service">Sicherheits <br> management</router-link></a></h3>
                                        <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie einem kompletten Team zuordnen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="choose-us-item-02">
                                    <div class="choose-us-img">
                                        <img src="../../assets/images/ser-icon12.png" alt="">
                                    </div>
                                    <div class="choose-us-content">
                                        <h3 class="title"><a><router-link to="/service">Desktop <br> Computing</router-link></a></h3>
                                        <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie einem kompletten Team zuordnen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="choose-us-item-02">
                                    <div class="choose-us-img">
                                        <img src="../../assets/images/counter-1.png" alt="">
                                    </div>
                                    <div class="choose-us-content">
                                        <h3 class="title"><a><router-link to="/service">Infrastruktur <br> technologie </router-link></a></h3>
                                        <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie einem kompletten Team zuordnen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="choose-us-item-02">
                                    <div class="choose-us-img">
                                        <img src="../../assets/images/counter-2.png" alt="">
                                    </div>
                                    <div class="choose-us-content">
                                        <h3 class="title"><a><router-link to="/service">Ideen für  <br>hochrentable Investitionen</router-link></a></h3>
                                        <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie einem kompletten Team zuordnen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="choose-us-item-02">
                                    <div class="choose-us-img">
                                        <img src="../../assets/images/ser-icon21.png" alt="">
                                    </div>
                                    <div class="choose-us-content">
                                        <h3 class="title"><a><router-link to="/service">Vollumfängliches <br> IT-Management</router-link></a></h3>
                                        <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie einem kompletten Team zuordnen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="choose-us-item-02">
                                    <div class="choose-us-img">
                                        <img src="../../assets/images/ser-icon22.png" alt="">
                                    </div>
                                    <div class="choose-us-content">
                                        <h3 class="title"><a><router-link to="/service">Datensicherung und -management</router-link></a></h3>
                                        <p>Beschleunigen Sie Innovation mit erstklassigen Technik-Teams. Wir werden Sie einem kompletten Team zuordnen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

</template>

<script>
export default {
    name: 'WhyUsSection'
}
</script>

<style>
    
</style>