import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './assets/css/plugins/all.min.css';
import './assets/css/plugins/flaticon.css';
import './assets/css/plugins/bootstrap.min.css';
import './assets/css/plugins/swiper-bundle.min.css';
import './assets/css/plugins/aos.css';
import './assets/css/plugins/magnific-popup.css';
import './assets/css/style.css';
import './assets/js/main.js';

import 'bootstrap';

createApp(App).use(router).mount('#app')
