<template>
    <MainLayout>
        <BannerView title="Warum Uns wählen" breadcrumb="Home" breadcrumb-item="Warum Uns wählen" />
        <WhyUsSection />
        <WhyUsSkills />
        <MaverickSectionView 
            title="WIR SIND MAVERICK" style="margin-top: 100px; background-color: orange;"
            contentH4="Die MAVERICK finden Sie in der Englischen Planke 2, das ist in 20459 Hamburg. Die Hausnummer 2 finden Sie gleich rechts an der Ecke Ludwig-Erhard-Straße und Englische Planke. Es ist das weiße Bürogebäude. Die MAVERICK befindet sich im Erdgeschoß. Das Haus wird gerne übersehen, weil die St. Michaelis Kirche, oder kurz unser Michel sehr beeindrucket direkt vor einem erscheint. In der Nähe befinden sich Parkhäuser, am besten nehmen Sie das vom Michel." />
        <BrandsSectionView />
        <AboutCta />
    </MainLayout>
</template>

<script>
import BannerView from '@/components/General/BannerView.vue';
import BrandsSectionView from '@/components/General/BrandsSectionView.vue';
import MaverickSectionView from '@/components/General/MaverickSectionView.vue';

import WhyUsSection from '@/components/WhyUs/WhyUsSection.vue';
import WhyUsSkills from '@/components/WhyUs/WhyUsSkills.vue';

import MainLayout from '@/components/MainLayout.vue';

import AboutCta from '@/components/About/AboutCta.vue'


export default {
    name: 'WhyUsView',
    components: { 
        BannerView,MainLayout,BrandsSectionView, MaverickSectionView,
        WhyUsSection, WhyUsSkills, AboutCta
     },
    mounted() {
        this.initializeProgressBars();
    },
    methods: {
        initializeProgressBars() {
            const progressBars = this.$el.querySelectorAll('.progress-line');
            progressBars.forEach(bar => {
                const width = bar.getAttribute('data-width');
                bar.style.width = '0%';
                setTimeout(() => {
                    bar.style.width = `${width}%`;
                },0);
            });
        }
    }
}
</script>

<style>
.test9 {
    background-image: url(../assets/images/bg/skill-bg.jpg)
}

.test6 {
    background-image: url(../assets/images/bg/cta-bg.jpg);
}

.test7 {
    background-image: url(../assets/images/bg/team-bg1.jpg);
}

.title-33 {
    font-size: 33px !important;
}
</style>