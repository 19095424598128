<template>
    <MainLayout>
        <BannerView title="Service" breadcrumb="Home" breadcrumb-item="Service" />
        <OurService />
        <ServiceCta />
        <KundenBewertungView />
        <BrandsSectionView />
        <AboutCta />
    </MainLayout>
</template>

<script>
import OurService from '@/components/Service/OurService.vue';
import ServiceCta from '@/components/Service/ServiceCta.vue';
import KundenBewertungView from '@/components/Service/KundenBewertungView.vue';

import BrandsSectionView from '@/components/General/BrandsSectionView.vue';
import BannerView from '@/components/General/BannerView.vue';

import MainLayout from '@/components/MainLayout.vue';

import AboutCta from '@/components/About/AboutCta.vue'


export default {
    name: 'ServiceView',
    components: { MainLayout,BannerView,BrandsSectionView,KundenBewertungView, 
        OurService, ServiceCta, AboutCta }
}
</script>

<style scoped>
.test8 {
    background: url(../assets/images/bg/cta-bg3.jpg) !important;
    z-index: 1000;
}

.test9 {
    background-image: url(../assets/images/bg/testi-bg4.jpg);
}

.test6 {
    background-image: url(../assets/images/bg/cta-bg.jpg);
}

.title-33 {
    font-size: 33px !important;
}

</style>