<template>
  <div class="section maverick-about-section-02 section-padding noselect nodrag">
    <div class="container">
      <div class="about-wrap">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-02-left text-center">
              <div class="section-title">
                <h2 class="title">Sie wissen, dass Sie alles richtig gemacht haben, wenn all Ihre Anstrengungen auf
                  unerwartete und beeindruckende Weise Früchte tragen</h2>
              </div>
              <div class="about-author">
                <img :src="require('@/assets/images/sign.png')" alt="">
                <h3 class="name">Stefan Mulack</h3>
                <span class="designation">CEO, Maverick</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-02-right">
              <p>Beschleunigen Sie Innovation mit erstklassigen Technikteams. Wir finden für Sie ein komplettes
                Remote-Team mit unglaublichen freiberuflichen Talenten für all Ihre Bedürfnisse in der
                Softwareentwicklung.</p>
              <div class="about-list">
                <ul>
                  <li>
                    <span class="about-icon"><i class="fas fa-check"></i></span>
                    <span class="about-text">Wir legen stets einen Fokus auf technische Exzellenz</span>
                  </li>
                  <li>
                    <span class="about-icon"><i class="fas fa-check"></i></span>
                    <span class="about-text">Wohin auch immer Sie gehen, wir bringen Ideen und Begeisterung mit</span>
                  </li>
                  <li>
                    <span class="about-icon"><i class="fas fa-check"></i></span>
                    <span class="about-text">Wir sind Berater, Wegweiser und Partner für Marken</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CtoSectionView.vue',
}
</script>
<style lang="">
    
</style>