<template>
  <div class="test3 section footer-section noselect nodrag">
    <div class="container">
      <div class="footer-widget-wrap">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget-about">
              <a class="footer-logo"><router-link to="/"><img :src="require('@/assets/images/logoss.png')"
                  alt="Logo"></router-link>
              </a>
              <p>
                Beschleunigen Sie die Innovation mit erstklassigen Technikteams. Wir werden Sie mit einem ganzen Team von
                unglaublichen freiberuflichen Talenten zusammenbringen.</p>
              <div class="footer-social">
                <ul class="social">
                  <li><a href="tel:+4940696355630"><i class="fa fa-phone"></i></a></li>
                  <li><a href="mailto:info@maverick-media.com"><i class="fa fa-envelope"></i></a></li>
                  <li><a href="https://www.google.com/maps/search/?api=1&query=ABC+Str.+21%2C+20354+Hamburg"
                      target="_blank"><i class="fa fa-map"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h4 class="footer-widget-title">Nützliche Links</h4>
              <div class="widget-link">
                <ul class="link">
                  <li><a><router-link to="/contact">kontakt</router-link></a></li>
                  <li><a><router-link to="/about">Über uns</router-link></a></li>
                  <li><a><router-link to="/impressum">Impressum</router-link></a></li>
                  <li><a><router-link to="/datenschutz">Datenschutz</router-link></a></li>

                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h4 class="footer-widget-title">Unsere Dienstleistungen</h4>
              <div class="widget-link">
                <ul class="link">
                  <li><a><router-link to="/service">Maßgeschneiderte Softwarelösung</router-link></a></li>
                  <li><a><router-link to="/service">Geschäftstechnologielösung</router-link></a></li>
                  <li><a><router-link to="/service">Wiederherstellung und IT-Sicherheit</router-link></a></li>
                  <li><a><router-link to="/service">Ideen generieren und Lösungen finden</router-link></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h4 class="footer-widget-title">Kontaktinformationen</h4>
              <div class="widget-info">
                <ul>
                  <li>
                    <div class="info-icon">
                      <i class="flaticon-phone-call"></i>
                    </div>
                    <div class="info-text">
                      <span><a href="tel:+4940696355630">+49 (0) 40 69 63 55 63 0</a></span>
                    </div>
                  </li>
                  <li>
                    <div class="info-icon">
                      <i class="far fa-envelope-open"></i>
                    </div>
                    <div class="info-text">
                      <span><a href="mailto:info@maverick-media.com">info@maverick-media.com</a></span>
                    </div>
                  </li>
                  <li>
                    <div class="info-icon">
                      <i class="flaticon-pin"></i>
                    </div>
                    <div class="info-text">
                      <a href="https://www.google.com/maps/search/?api=1&query=ABC+Str.+21%2C+20354+Hamburg"
                        target="_blank">ABC Str. 21, 20354 Hamburg</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright-area">
      <div class="container">
        <div class="footer-copyright-wrap">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="copyright-text text-center">
                <p>© Copyrights 2024 Maverick, All rights reserved. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterSectionView',
}
</script>
<style scoped>
.test3 {
  background-image: url(../../assets/images/bg/footer-bg.jpg);
}</style>